import React, { useState } from "react";
import Header from "../../components/UI/Header/Header";
import HeaderImg from "../../images/headers/education-center-header01.jpg";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import Slide from "react-reveal/Fade";
import KeepExploring from "../../components/UI/Button/KeepExploring";
import "../../components/Modals/ModalsCSS/modal.css";
import ReactGA from "react-ga";
import LinkedInTag from "react-linkedin-insight";
import PresentationModals from "../components/PresentationModals/PresentationModals";
// import ButtonElement from "../../components/UI/Button/ButtonElement";
// import ButtonLink from "../../components/UI/Button/ButtonLink";
import { Himss22_presentations } from "../components/SessionPresentationCards/sessionPresentationData";
import VideoModals from "../components/VideoModals/VideoModals";
import SessionPresentationCards from "../components/SessionPresentationCards/SessionPresentationCards";
import ThankYouModals from "../components/VideoModals/ThankYouModals/ThankYouModals";
import AccessRequests from "../components/VideoModals/AccessRequests/AccessRequests";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";

LinkedInTag.init("807690");

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Education = (props) => {
  // When the user clicks on "submit now in Request modal, it sets userSubmittedInfo to true (in App.js) and closes the modal"
  const onUserSubmission = (linkedinID, ThankyouModalID) => {
    props.handleUserAccess();
    onCloseModal();
    onOpenModal(ThankyouModalID);
    LinkedInTag.track(linkedinID);
    setDisable(true);
  };

  const [modalState, setModalState] = useState(null);
  const onOpenModal = (id) => {
    setModalState(id);
  };
  const onCloseModal = () => setModalState(null);

  const [disable, setDisable] = useState(false);

  // Custom SC Modal --------------------------
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // End SC custom modal ----------------------

  return (
    <>
      <PresentationModals modalId={modalState} closeModal={onCloseModal} />

      <AccessRequests
        modalId={modalState}
        closeModal={onCloseModal}
        hasAccess={props.gainVideoAccess}
        baseUrl="//go.bd.com"
        onUserSubmit={onUserSubmission}
      />

      <VideoModals modalId={modalState} closeModal={onCloseModal} />

      <ThankYouModals modalId={modalState} closeModal={onCloseModal} />

      <div className="container-fluid sessionCards">
        <div className="row">
          <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props} />
          </div>

          <div className="col-xl-10 bg-wild-sand subpage-content">
            <Header
              image={HeaderImg}
              title="Welcome to the Virtual Educational Center"
              description="Learn more about our HIMSS22 sessions below."
            />

            <div className="container-fluid pt-5">
              <div className="row">
                {/* <div className="col-md-8 offset-md-2">
                <div className="text-center mt-4 pt-2">
                  <h3 className= "grey-title">
                    If you are unable to attend in person and would like to view a session after HIMSS22, please request access by clicking the button below.
                  </h3>

                  <div className="mb-5 pt-4">
                    <ButtonLink to="/education-theater#ondemand">View on-demand sessions now</ButtonLink>
                  </div>
                  </div>
                </div> */}

                <div className="col-12 px-md-5" id="schedule">
                  <Slide left>
                    <h2 className="font-weight-bold grey-title mb-0">
                      Session Schedule
                    </h2>
                    {/* <h5 className="grey-title">All times in PT</h5> */}
                  </Slide>
                  <div className="hr-line mb-3"></div>

                  {/* Testing SC ========================================== */}
                  <div>
                    {/* <Button
                      variant="primary"
                      onClick={handleShow}
                      style={{
                        backgroundColor: "#004593",
                        padding: "10px",
                        width: "15%",
                      }}
                    >
                      View Presentation
                    </Button> */}

                    {/* <Modal
                      size="lg"
                      show={show}
                      onHide={() => setShow(false)}
                      aria-labelledby="view-presentation"
                    ></Modal> */}

                    {/* <Modal
                      className="border d-flex align-items-center justify-content-center"
                      show={show}
                      onHide={handleClose}
                      size="xl"
                    >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "white" }}
                      >
                        <Modal.Title
                          style={{ fontWeight: "600", color: "#004593" }}
                        >
                          Are You Exposed?
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <h3>
                          Evolving capital equipment strategies to reduce
                          cybersecurity risks
                        </h3>
                        <iframe
                          className="modal-video-style"
                          loading="lazy"
                          width="100%"
                          height="450vh"
                          src="https://player.vimeo.com/video/720922025?h=265f4d7eb2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"
                          title="BD Video Presentation"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowfullscreen"
                          padding="0"
                        ></iframe>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal> */}

                  </div>
                  {/* End SC test ======================================= */}

                  {/* <p className="lead text-uppercase font-weight-bold grey-title">Content coming soon</p> */}
                </div>
              </div>

              <SessionPresentationCards
                presentations={Himss22_presentations}
                openModalHandler={onOpenModal}
              />

              {/* <SessionPresentationCards openModalHandler={onOpenModal}  /> */}

              {/* <SessionScheduleCards sessionScheduleData={sessionScheduleData} /> */}

              <div className="row  pt-5 mt-5">
                {/* <div className="col-lg-3 col-md-4 mt-4">
                  <div>
                    <Fade bottom>
                      <img src={Image13} className="img-fluid" />
                    </Fade>
                  </div>
                </div> */}

                {/* <div className="col-lg-8 offset-lg-2 mt-5 pt-3">
                  <p className="h4 grey-title mt-4 text-height-1">
                      If you are unable to attend in person and would like to view a session after HIMSS22, please request access by clicking the button below.
                  </p>

                  <div className="mb-5 pt-3">
                    <ButtonElement disabled={disable} onClick={() => props.gainVideoAccess ? '' : onOpenModal("cantAttendRequestToView")}>Request access</ButtonElement>
                  </div>
                </div>  */}

                {/* <div className="mt-5 pt-5">
                  <div className="row " id="ondemand">
                    <div className="col-12">
                      <div>
                        <p className=" h2  font-weight-bold grey-title mb-0">
                          On-Demand Learning
                        </p>
                        <div className="hr-line mb-5"></div>
                      </div>
                    </div>
                  </div>

                    <VideoCards openModalHandler={onOpenModal} gainVideoAccess={props.gainVideoAccess} />

                    <div className="row py-lg-5 text-center">
                      <div className="col-12 text-center py-5">
                        <KeepExploring />
                      </div>
                    </div>
                  
                </div> */}

                <div className="col-12 px-md-5" id="schedule">
                  {/* <Slide left>
                      <h2 className="font-weight-bold grey-title mb-0">
                        On-Demand Learning
                      </h2> */}
                  {/* <h5 className="grey-title">All times in PT</h5> */}
                  {/* </Slide>
                    <div className="hr-line mb-3"></div>
                    <p className="lead text-uppercase font-weight-bold grey-title">Content coming soon</p> */}

                  <div className="row py-lg-5 text-center">
                    <div className="col-12 text-center py-5">
                      <KeepExploring />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Education;
